import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-activity-edition-horizontal-selector',
  templateUrl: './activity-edition-horizontal-selector.component.html',
  styleUrls: ['./activity-edition-horizontal-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityEditionHorizontalSelectorComponent<T extends string> {

    @Input() choices: {[key in T]: {label:string, icon: string}};
    @Input() selected: T;
    @Output() selectedChange = new EventEmitter<T>();

    public choicesArray: Array<{key: T, value: {label: string, icon: string}}> = [];
    public selectedIndex: number;

    ngOnChanges(changes: SimpleChanges): void {
        // this.
        if (changes.choices) {
            this.updateChoicesArray();
        }
        if (changes.selected) {
            this.selectedIndex = Object.keys(this.choices).indexOf(this.selected);
        }
    }

private updateChoicesArray(): void {
    if (!this.choices) {
        this.choicesArray = [];
        return;
    }

    this.choicesArray = Object.entries(this.choices).map(([key, value]: [string, {label: string, icon: string}]) => ({key: key as T, value}));
}
    // LOGIC

    private select(choice: T): void {
        this.selected = choice;
        this.selectedChange.emit(choice);
    }

    // EVENTS

    public onSelectedIndexChange($event: number) {
        const keys = Object.keys(this.choices) as T[];
        this.selectedIndex = $event;
        this.selectedChange.emit(keys[$event]);
    }
}
